// Web banners
// Mobile banners
import { CONFIG_PERMISSIONS } from './ConfigPermission';
import bk from '../../src/assets/images/home/casino/214x152_1 copy.png';
import autoroulette from '../../src/assets/images/home/casino/214x152_2 copy.png';
import dragontiger from '../../src/assets/images/home/casino/214x152_7 copy.png';
import rulet from '../../src/assets/images/home/casino/214x152_5 copy.png';
import baccarat from '../../src/assets/images/home/casino/214x152_4 copy.png';
import cards from '../../src/assets/images/home/casino/214x152_3 copy.png';
import hounds from '../../src/assets/images/home/casino/214x152_10 copy.png';
import roulette from '../../src/assets/images/home/casino/indianRoulette.png';
import betcity from '../../src/assets/images/home/casino/214x152_11 copy.png';
import blackjack from '../../src/assets/images/home/casino/214x152_9 copy.png';
import fortune from '../../src/assets/images/home/casino/214x152_6 copy.png';
import peakbaccrat from '../../src/assets/images/home/casino/214x152_8 copy.png';
import homeBanner from '../assets/images/common/home.jpg';
import homeBannerMob from '../assets/images/common/mob-home-banner.jpg';
import Img1 from '../assets/images/casino/cards/1.svg';
import Img2 from '../assets/images/casino/cards/2.svg';
import Img3 from '../assets/images/casino/cards/3.svg';
import Img4 from '../assets/images/casino/cards/4.svg';
import Img5 from '../assets/images/casino/cards/5.svg';
import Img6 from '../assets/images/casino/cards/SmartsoftLogo.svg';
import Img7 from '../assets/images/casino/cards/spribe.webp';
import Img8 from '../assets/images/casino/cards/11.svg';
import Img9 from '../assets/images/casino/cards/17.svg';
import Img10 from '../assets/images/casino/cards/gamzix.webp';
import Banner from '../assets/images/common/web-banner.jpg';
import BannerMob from '../assets/images/common/mob-banner.jpg';
const HOME_PAGE_HEADERS = {
  featureSports: 'Featured Sports',
  popularGames: 'Popular Games',
  liveGames: 'Live Casino',
};

const HOME_PAGE_IMAGES = {
  aviator: {
    image: bk,
    id: '525001:CRASH88_CAV101_VR:CRASH88:Crash88 Gaming',
    text: 'AVIATOR',
  },
  autoroulette: {
    image: autoroulette,
    id: '100035:ezg_speedautoroulette:EZUGI:Ezugi',
    text: 'Auto Roulette',
  },
  baccaratLive: {
    image: baccarat,
    id: '200150:evo_no_commission_baccarat:DC:Evolution Gaming',
    text: 'Baccarat Live',
  },
  rouletteLive: {
    image: rulet,
    id: '200257:evo_indianroulette:DC:Evolution Gaming',
    text: 'Roulette Live',
  },
  wheelOfFortune: {
    image: fortune,
    id: '201153:bgt_andarbahar:DC:BetGames.TV',
    text: 'Wheel Of Fortune',
  },

  Cards32: {
    image: cards,
    id: '100067:ezg_32cards:EZUGI:Ezugi',
    text: '32 Cards',
  },
  dragontiger: {
    image: dragontiger,
    id: '200139:ezg_speedcricketbaccarat:DC:Evolution Gaming',
    text: 'Speed Cricket Baccarat',
    gameName: 'Dragon Tiger',
  },
  houseOfDragons: {
    image: hounds,
    id: '600009:jili_7_updown:JiLi:JiLi Gaming',
    text: 'eBET',
  },
  roulette: {
    image: roulette,
    id: '400006:ezg_speedroulette:AWC:SEXYBCRT',
    text: 'Roulette',
  },
  speed_auto_roulette: {
    image: peakbaccrat,
    id: '200000:evo_peekbaccarat:DC:Evolution Gaming',
    text: 'Speed Auto Roulette',
    gameName: 'poker',
  },
  teen_patti: {
    image: 'https://cdn.dreamcasino.live/ezg_teenpatti.jpg',
    id: '100046:ezg_teenpatti:EZUGI:EZUGI',
    text: 'Teen Patti',
    gameName: 'EZUGI',
  },
  one_day_teen_patti_classic: {
    image: 'https://cdn.dreamcasino.live/ezg_onedayteenpatticlassic.jpg',
    id: '100048:ezg_onedayteenpatticlassic:EZUGI:EZUGI',
    text: 'One Day Teen Patti Classic',
    gameName: 'EZUGI',
  },
  bet_on_teen_patti: {
    image: blackjack,
    id: '200242:ezg_baccaratlobby:DC:Evolution Gaming',
    text: 'Blackjack',
  },
  rct: {
    image: betcity,
    id: '900034:RG-SBC101:RG:Royal Gaming',
    text: 'virtual cricket',
    gameName: 'evolution',
  },
};

const HOME_PAGE_WEB_BANNERS = [
  {
    image: 'https://sky-asserts.s3.eu-west-2.amazonaws.com/banner1-web.png',
    text: 'WORLD_CUP',
    url: '/',
    configPermission: CONFIG_PERMISSIONS.cricket,
  },
  {
    image: 'https://sky-asserts.s3.eu-west-2.amazonaws.com/banner2-web.png',
    text: 'WORLD_CUP',
    url: '/',
    configPermission: CONFIG_PERMISSIONS.cricket,
  },
  {
    image: 'https://sky-asserts.s3.eu-west-2.amazonaws.com/banner3-web.jfif',
    text: 'WORLD_CUP',
    url: '/',
    configPermission: CONFIG_PERMISSIONS.cricket,
  },
  {
    image: Banner,
    text: 'WORLD_CUP',
    url: '/',
    configPermission: CONFIG_PERMISSIONS.cricket,
  },
];

const WHITE_LABEL_BANNERS = [
  {
    image: 'https://sky-asserts.s3.eu-west-2.amazonaws.com/wl_banner1.png',
    text: 'WORLD_CUP 2021',
    url: `/exchange_sports/cricket`,
    configPermission: CONFIG_PERMISSIONS.sports,
  },
];

const HOME_PAGE_MOB_BANNERS = [
  {
    image: 'https://sky-asserts.s3.eu-west-2.amazonaws.com/banner1-mob.png',
    text: 'WORLD_CUP',
    url: '/',
    configPermission: CONFIG_PERMISSIONS.cricket,
  },
  {
    image: 'https://sky-asserts.s3.eu-west-2.amazonaws.com/banner2-mob.png',
    text: 'WORLD_CUP',
    url: '/',
    configPermission: CONFIG_PERMISSIONS.cricket,
  },
  {
    image: 'https://sky-asserts.s3.eu-west-2.amazonaws.com/banner3-mob.jfif',
    text: 'WORLD_CUP',
    url: '/',
    configPermission: CONFIG_PERMISSIONS.cricket,
  },

  {
    image: BannerMob,
    text: 'WORLD_CUP',
    url: '/',
    configPermission: CONFIG_PERMISSIONS.cricket,
  },
];

const WHITE_LABEL_BANNERS_MOBILE = [
  {
    image: 'https://sky-asserts.s3.eu-west-2.amazonaws.com/wl_banner1.png',
    text: 'WORLD_CUP 2021',
    url: `/exchange_sports/cricket`,
    configPermission: CONFIG_PERMISSIONS.sports,
  },
];

const EVENT_PAGE_IMAGES = {
  cricket: {
    image: homeBanner,
    text: 'Cricket',
    url: '/exchange_sports/cricket',
  },
  soccer: {
    image: homeBanner,
    text: 'Soccer',
    url: '/exchange_sports/football',
  },
  Football: {
    image: homeBanner,
    text: 'Football',
    url: '/exchange_sports/football',
  },
  tennis: {
    image: homeBanner,
    text: 'Tennis',
    url: '/exchange_sports/tennis',
  },
};

export const CASINO_WEB_NAV_PROVIDERS = [
  {
    image: Img1,
    text: 'Royal Casino',
    url: '/casino/royal gaming',
  },
  {
    image: Img2,
    text: 'Betsoft',
    url: '/casino/betsoft',
  },
  {
    image: Img3,
    text: 'EZUGI',
    url: '/casino/ezugi',
  },
  {
    image: Img4,
    text: 'Supernowa',
    url: '/casino/supernowa',
  },
  {
    image: Img5,
    text: 'One Touch Casino',
    url: '/casino/evolution gaming',
  },
  {
    image: Img6,
    text: 'Smartsoft  Gaming Casino',
    url: '/casino/Smartsoft Gaming',
  },
  {
    image: Img7,
    text: 'Spribe',
    url: '/casino/Spribe',
  },
  {
    image: Img8,
    text: 'Asia Casino',
    url: '/casino/sexybcrt',
  },
  {
    image: Img9,
    text: 'Casino',
    url: '/casino/jili gaming',
  },
  {
    image: Img10,
    text: 'Gamzix',
    url: '/casino/Gamzix',
  },
];
export default {
  HOME_PAGE_HEADERS,
  HOME_PAGE_IMAGES,
  WHITE_LABEL_BANNERS,
  WHITE_LABEL_BANNERS_MOBILE,
  HOME_PAGE_WEB_BANNERS,
  HOME_PAGE_MOB_BANNERS,
  EVENT_PAGE_IMAGES,
  CASINO_WEB_NAV_PROVIDERS,
};
